import React from 'react'
import {graphql, useStaticQuery} from "gatsby";

export const OpeningTimes = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          opening_times
        }
      }
    }
  `)

  const openingTimes = data.site.siteMetadata.opening_times

  return (<>{openingTimes.split("\n").map((_bit, idx,_arr) => (
    <React.Fragment key={idx}>{_bit}{idx<_arr.length-1?<br/>:null}</React.Fragment>
  ))}</>)
}