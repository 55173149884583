import React from "react"
import {dentists} from "./team";
import Layout from "../components/layout"
import imgHome from '../images/top_home.jpg'
import SEO from "../components/seo"
import {Qualifications} from "../components/qualifications";
import {OpeningTimes} from "../components/openingTimes";

const IndexPage = () => {


  return (
    <Layout pageTitle="Home" headerImage={imgHome}>
      <SEO title="Home"/>
      <div className="clearfix priority-banner">
        <h2 className="section-title">CORONAVIRUS: WE ARE OPEN FOR IN-PERSON APPOINTMENTS</h2>

        <div className="general-text">
          <p>We are now fully open for in-person appointments (both private and NHS), covering all types of dentistry: pain/ emergencies/ infection, check ups and cleans, implants, braces, wrinkle treatment. We have new safety procedures in place and all the required Personal Protective Equipment.</p>
          <p>Call us on <a href="tel:+441883712486" title="Click here to call us on: 01883 712486">01883 712486</a>.</p>
        </div>

        <hr/>
      </div>
      <div className="clearfix new-row">
        <div className="two-col-1 slim">
          <span className="blurb">Established for over 70 years, Pipers Dental Practice is committed to providing high quality professional and gentle dental care for you and your family, at an affordable price</span>
        </div>
        <div className="two-col-2 fat">
          <h2 className="section-title">Welcome to <span className="bold-green">Pipers</span> dental practice</h2>
          <p className="general-text">Our established, family-oriented practice will provide you with the highest
            standard
            of dental treatment in a modern, caring environment. We treat patients
            as individuals and always have time to listen to questions with kindness, courtesy and patience.</p>
          <p className="general-text">As a practice we are dedicated to preventive dentistry. We believe this is
            important
            for our patients because healthy teeth and gums will benefit your overall health
            and well-being.
          </p>
          <p className="general-text">Whether making a simple appointment or undertaking a complex course of treatment,
            you can feel safe and confident in the friendly and professional environment of our practice.
          </p>

        </div>
      </div>
      <div className="new-row clearfix">
        <div className="two-col-1 slim">
          <h3 className="info-title">Practice Opening Hours</h3>
          <span className="general-text"><OpeningTimes /></span>
        </div>
        <div className="two-col-2 fat">
          <h3 className="info-title">Dentists</h3>
          <ul className="dentists">
            {dentists.map((_dentist, idx) => (
              <li key={idx}><span className="general-text">{_dentist.name}</span> <span className="gdc-number"><abbr title="General Dental Council Registration Number">GDC</abbr> {_dentist.gdcNumber}</span> <Qualifications qualifications={_dentist.qualifications} /></li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  )
}



export default IndexPage
